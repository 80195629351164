import { useEffect } from 'react'

export const useDocumentVisibilityChange = ( callback: EventListener ) => {
  useEffect( () => {
    document.addEventListener( 'visibilitychange', callback );
       
    return () => {
      document.removeEventListener( 'visibilitychange', callback );
    }
  } )
}
