import { useNavigation, useRevalidator } from '@remix-run/react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react';

export const GlobalLoading = () => {
  const navigation = useNavigation();
  const revalidator = useRevalidator();
  const active = navigation.state !== 'idle' || revalidator.state !== 'idle';

  const ref = useRef<HTMLDivElement>( null );
  const [ animationCompleted, setAnimationCompleted ] = useState<boolean>( true );

  useEffect( () => {
    if ( !ref.current ) {
      return;
    }

    if ( active ) {
      setAnimationCompleted( false );
    }

    Promise.allSettled(
      ref.current.getAnimations().map( ( animation ) => animation.finished )
    ).then( () => !active && setTimeout( () => { setAnimationCompleted( true ) }, 300 ) )
  }, [ active ] );

  return (
    <div className={ clsx(
      'fixed top-0 h-0.5 inset-x-0 z-[10000]',
      {
        'bg-muted': active
      }
    ) }>
      <div 
        ref={ ref }
        className={ clsx(
          'bg-primary w-1/4 h-0.5 transition-all duratino-500 ease-in-out',
          {
            'w-0 opacity-0 transition-none': ( navigation.state === 'idle' && revalidator.state === 'idle' ) && animationCompleted,
            'w-4/12': navigation.state === 'submitting',
            'w-10/12': revalidator.state === 'loading' || navigation.state === 'loading',
            'w-full': navigation.state === 'idle' && revalidator.state === 'idle' && !animationCompleted
          }
        ) } />
      { active &&
        <div className={ 'fixed z-[10001] inset-0 cursor-wait' } />
      }
    </div>
  )
}