import { ImageContextProvider } from '@mechhive/react';
import { Link, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import type { LinksFunction, LoaderFunctionArgs } from '@vercel/remix';
import { json, redirect } from '@vercel/remix';
import acceptLanguageParser from 'accept-language-parser';
import classNames from 'classnames';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import stylesheet from '~/tailwind.css?url';
import { createInternalApi } from './api/internal.api';
import { GlobalLoading } from './components/GlobalLoading';
import { Icon } from './components/icon';
import { Button } from './components/shadcn/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './components/shadcn/components/ui/card';
import { useDocumentVisibilityChange } from './hooks/useDocumentVisibilityChange';
import { commitSession, getSession, isAuthTokenExpired } from './session.server';
import { Theme, useThemeStore } from './stores/ThemeStore';

export const loader = async ( { request }: LoaderFunctionArgs ) => {
  const url = new URL( request.url );
  const session = await getSession( request.headers.get( 'Cookie' ) );
  const authTokenExpired = isAuthTokenExpired( session.get( 'authToken' ) );

  if ( 
    !url.pathname.startsWith( '/login' ) &&
    ( session == null || session.data.authToken == null || session.data.user == null || session.data.roles == null || authTokenExpired )
  ) { 
    if ( authTokenExpired ) {
      session.flash( 'sessionExpired', true );
    }

    const searchParams = new URLSearchParams( {
      redirectUrl: url.pathname
    } )

    return redirect( url.pathname === '/' ? '/login' : `/login?${searchParams.toString()}`, {
      headers: {
        'Set-Cookie': await commitSession( session )
      }
    } );
  }
  
  const acceptLanguage = acceptLanguageParser.parse( request.headers.get( 'accept-language' ) ?? '' )?.[0]?.code

  return json( {
    language: acceptLanguage,
    user: session.get( 'user' ),
    roles: session.get( 'roles' ),
    theme: session.get( 'theme' ) ?? 'light',
    env: {
      NODE_ENV: process.env.NODE_ENV,
      GOOGLE_AUTH_CLIENT_ID: process.env.GOOGLE_AUTH_CLIENT_ID as string,
      SENTRY_IO_DSN: process.env.SENTRY_IO_DSN as string,
      COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA as string
    }
  } )
}

export type RootLoader = typeof loader;

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'stylesheet', href: 'https://d1ffhb7eapp0ca.cloudfront.net/assets/fonts/hugeicons/v2/hugeicons-font.css' }
];

export function ErrorBoundary() {  

  return (
    <div className={ 'w-screen h-screen bg-background flex flex-col items-center justify-center gap-4' }>
      <div className={ 'max-w-sm flex flex-col gap-4' }>
        <Card className={ 'flex flex-col bg-muted/40' }>
          <CardHeader>
            <CardTitle>{ 500 }</CardTitle>
            <CardDescription>{ 'Internal Server Error' }</CardDescription>
          </CardHeader>
          <CardContent className={ 'text-sm' }>
            <p>We regret to inform you that our system is currently unable to handle your request due to an internal error.</p>
            <p>Please try to refresh the page or go back to the dashboard.</p>
          </CardContent>
          <CardFooter className={ 'flex-row gap-4 justify-between' }>
            <Link to={ '' }>
              <Button
                variant='outline'
                className={ 'flex-row gap-2.5' }
                size={ 'sm' }>
                <Icon
                  name={ 'home-01' }
                  className={ 'text-lg' } />
                Return to Dashboard
              </Button>
            </Link>
            <Button
              variant='default'
              size={ 'sm' }
              onClick={ () => { window.location.reload() } }
              className={ 'flex-row gap-2.5' }
            >
              Refresh
              <Icon
                name={ 'arrow-reload-horizontal' }
                className={ 'text-lg' } />
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export function Layout( { children } ) {
  const loaderData = useLoaderData<typeof loader>();
  const internalApi = createInternalApi();
  const [ initialized, setInitialized ] = useState<boolean>( false );
  const [ newUpdateAvailable, setNewUpdateAvailable ] = useState<boolean>( false );
  const theme = useThemeStore( state => state.theme );
  const setTheme = useThemeStore( state => state.setTheme );

  const currentTheme = useMemo( () => {
    return theme === Theme.Dark ? 'dark' : '';
  }, [ theme ] );

  const handleDocumentVisibilityChange = async () => {
    if ( document.hidden === false ) {
      const response = await internalApi.checkUpdate( loaderData?.env.COMMIT_SHA );
      setTimeout( () => {
        setNewUpdateAvailable( response.data.newUpdateAvailable );
      }, 250 );
    }
  };
  
  const handleReloadAppClick = () => {
    window.location.reload();
  }

  useEffect( () => {
    setTimeout( () => {
      setTheme( loaderData?.theme as Theme );
      setInitialized( true );
    }, 300 );
  }, [] );
  
  useDocumentVisibilityChange( handleDocumentVisibilityChange );

  return (
    <html
      lang="en"
      className={ clsx(
        'bg-background transition-all duration-200',
        currentTheme
      )  }
      style={ {
        opacity: !initialized ? 0 : 1,
      } }>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link
          rel="manifest"
          href="/manifest.webmanifest"></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#151518" />
        <meta
          name="msapplication-TileColor"
          content="#151518" />
        <meta
          name="theme-color"
          content="#000000"></meta>
        <Meta />
        <Links />
      </head>
      <body>
        <GlobalLoading />
        { newUpdateAvailable &&
          <div className={ 'fixed lg:static bottom-8 lg:bottom-[auto] lg:top-8 left-0 right-0 z-[2000] flex items-center justify-center lg:items-stretch lg:justify-stretch' }>
            <div
              className={ classNames(
                'bg-orange-400 dark:bg-orange-950 border-b border-b-orange-600 dark:border-b-orange-800 text-primary lg:flex-auto lg:rounded-none text-center p-4 py-3 text-sm rounded-full drop-shadow-lg lg:drop-shadow-none tracking-tight',
                newUpdateAvailable ? 'mb-0' : '-mb-48'
              ) }
              onClick={ handleReloadAppClick }>
              New Update Available <span className={ 'font-bold underline underline-offset-2 cursor-pointer' }>Click here</span> to reload.
            </div>
          </div>
        }
        { children }
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={ {
            __html: `window.env = ${JSON.stringify(
              loaderData?.env
            )}`,
          } }
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  
  return (
    <ImageContextProvider providers={ [{
      name: 'rewarble-images',
      baseUrl: 'https://images.rewarble.com',
      optimized: true
    },
    {
      name: 'skine-images',
      baseUrl: 'https://images.skine.com',
      optimized: true
    },
    ] }>
      <Outlet />
    </ImageContextProvider>
  )
}
